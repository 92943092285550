// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-greeting-js": () => import("./../../../src/pages/about/greeting.js" /* webpackChunkName: "component---src-pages-about-greeting-js" */),
  "component---src-pages-about-history-js": () => import("./../../../src/pages/about/history.js" /* webpackChunkName: "component---src-pages-about-history-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-access-js": () => import("./../../../src/pages/access.js" /* webpackChunkName: "component---src-pages-access-js" */),
  "component---src-pages-admissions-js": () => import("./../../../src/pages/admissions.js" /* webpackChunkName: "component---src-pages-admissions-js" */),
  "component---src-pages-application-js": () => import("./../../../src/pages/application.js" /* webpackChunkName: "component---src-pages-application-js" */),
  "component---src-pages-calendar-js": () => import("./../../../src/pages/calendar.js" /* webpackChunkName: "component---src-pages-calendar-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-downloads-js": () => import("./../../../src/pages/downloads.js" /* webpackChunkName: "component---src-pages-downloads-js" */),
  "component---src-pages-english-education-js": () => import("./../../../src/pages/english-education.js" /* webpackChunkName: "component---src-pages-english-education-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-home-school-js": () => import("./../../../src/pages/home-school.js" /* webpackChunkName: "component---src-pages-home-school-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-open-house-js": () => import("./../../../src/pages/open-house.js" /* webpackChunkName: "component---src-pages-open-house-js" */),
  "component---src-pages-school-bus-js": () => import("./../../../src/pages/school-bus.js" /* webpackChunkName: "component---src-pages-school-bus-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-trial-class-js": () => import("./../../../src/pages/trial-class.js" /* webpackChunkName: "component---src-pages-trial-class-js" */),
  "component---src-pages-workshops-js": () => import("./../../../src/pages/workshops.js" /* webpackChunkName: "component---src-pages-workshops-js" */),
  "component---src-templates-admissions-js": () => import("./../../../src/templates/admissions.js" /* webpackChunkName: "component---src-templates-admissions-js" */),
  "component---src-templates-faq-js": () => import("./../../../src/templates/faq.js" /* webpackChunkName: "component---src-templates-faq-js" */),
  "component---src-templates-fundraisings-archive-js": () => import("./../../../src/templates/fundraisings-archive.js" /* webpackChunkName: "component---src-templates-fundraisings-archive-js" */),
  "component---src-templates-fundraisings-js": () => import("./../../../src/templates/fundraisings.js" /* webpackChunkName: "component---src-templates-fundraisings-js" */),
  "component---src-templates-news-archive-js": () => import("./../../../src/templates/news-archive.js" /* webpackChunkName: "component---src-templates-news-archive-js" */),
  "component---src-templates-news-js": () => import("./../../../src/templates/news.js" /* webpackChunkName: "component---src-templates-news-js" */),
  "component---src-templates-proficiency-tests-archive-js": () => import("./../../../src/templates/proficiency-tests-archive.js" /* webpackChunkName: "component---src-templates-proficiency-tests-archive-js" */),
  "component---src-templates-proficiency-tests-js": () => import("./../../../src/templates/proficiency-tests.js" /* webpackChunkName: "component---src-templates-proficiency-tests-js" */),
  "component---src-templates-summer-winter-schools-archive-js": () => import("./../../../src/templates/summer-winter-schools-archive.js" /* webpackChunkName: "component---src-templates-summer-winter-schools-archive-js" */),
  "component---src-templates-summer-winter-schools-js": () => import("./../../../src/templates/summer-winter-schools.js" /* webpackChunkName: "component---src-templates-summer-winter-schools-js" */),
  "component---src-templates-workshops-js": () => import("./../../../src/templates/workshops.js" /* webpackChunkName: "component---src-templates-workshops-js" */)
}

